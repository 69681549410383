import { Option } from 'components/Forms/types';
import { PAGINATION_NAV } from 'components/Pagination/constants';
import { PaginationConfig } from 'components/Pagination/types';
import { PanelTheme } from 'components/PanelWrapper/types';
import { Vertical } from 'globalConstants';

export interface TableHeaderCellProps {
  [key: string]: any;
  title: string;
  isSortable?: { key?: string; type: SortTypes };
  isHeadingHidden?: boolean;
  isWide?: boolean;
  isFixed?: boolean;
  isVisible?: boolean;
  headerKey?: string;
  children?: TableHeaderCellProps[];
  childrenVisible?: boolean;
  toggleChildren?: (key: string) => void;
  isParent?: boolean;
  parentCol?: string;
  childrenCount?: number;
  isChild?: boolean;
  isLastChild?: boolean;
  key?: string;
  isExportOnly?: boolean;
}

export interface TableHeaderProps extends TableHeaderCellProps {
  useTheme: PanelTheme;
  columns: number;
  sortConfig?: SortConfig;
  sortRows: (key: string, type: SortTypes, direction?: SortDirections) => void;
  isExpandedHeadersWhiteBackground?: boolean;
}
export interface TableWrapperProps {
  tableId: string;
  headers: TableHeaderCellProps[];
  rows: TableRowProps[];
  useTheme?: PanelTheme;
  columns: number;
  usePagination?: boolean;
  tableItemsConfig?: TableConfigProps;
  totalRows?: number;
  tableUseContext?: 'DETAILS' | 'RESULTS';
  isMergeRecordEnabled?: boolean;
  vertical?: Vertical;
}
export interface DynamicTableWrapperProps extends TableWrapperProps {
  rowCount: number;
  editInSitu?: boolean;
}

export interface TableProps {
  tableId: string;
  headers: TableHeaderCellProps[];
  rows: TableRowProps[];
  useTheme?: PanelTheme;
  columns: number;
  usePagination?: boolean;
  tableItemsConfig: TableConfigProps;
  rowCount: number;
  hideBlankOption?: boolean;
  editInSitu?: boolean;
  tableUseContext?: 'DETAILS' | 'RESULTS';
  isMergeRecordEnabled?: boolean;
  vertical?: Vertical;
}

export interface TableConfigProps {
  sortRows: (key: string, type: SortTypes) => void;
  sortConfig?: SortConfig;
  updatePaginationPage: (
    type: PAGINATION_NAV,
    limit: string,
    total: number,
  ) => void;
  updatePaginationLimit: (limit: string, label: string) => void;
  updatedLimit: number;
  pagination: PaginationConfig;
  paginationOptions?: Option[];
  toggleChildren?: (key: string) => void;
}

export enum DocumentFileTypes {
  doc = 'doc',
  docx = 'docx',
  pdf = 'pdf',
  xls = 'xls',
  xlsx = 'xlsx',
  png = 'png',
  jpg = 'jpg',
  jpeg = 'jpeg',
  avux = 'avux',
  ppt = 'ppt',
  pptx = 'pptx',
}

export type TableCellProps = {
  [key: string]: any;
  heading?: string;
  isKey?: boolean;
  icon?: DocumentFileTypes;
  value: any;
  subValue?: any;
  actions?: Actions[];
  tagline?: string;
  linkTo?: string;
  savCrmId?: string;
  savCrmNearestId?: string;
  hasHighlight?: boolean;
  cellType?: string;
  isConfirmedTotalRent?: boolean;
  key?: string;
  hasMatchingRecords?: {
    matches: any;
    indicator: string;
    vertical: Vertical;
  };
  vertical?: Vertical;
};

export type TableRowProps = {
  [key: string]: TableCellProps;
};

export type Actions = {
  type: string;
  isDisabled: boolean;
  handleAction?: () => void;
  link?: string;
  canBeRemoved?: boolean;
  showTooltipIfDisabled?: string;
  isAdminOnly?: boolean;
  tooltipContent?: string;
  id?: string;
  modalConfig?: {
    modalId: string;
    recordId?: string;
    triggerButton?: string;
    vertical?: Vertical;
    modalType?: string;
  };
  vertical?: Vertical;
};

export enum SortDirections {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SortTypes {
  string = 'string',
  date = 'date',
  dateString = 'dateString',
  numeric = 'numeric',
}

export type SortConfig = {
  key?: string;
  label?: string;
  direction?: SortDirections;
  type?: SortTypes;
};

export type SortIconProps = {
  title: string;
  direction: SortDirections;
  sortConfig?: SortConfig;
  onClick: () => void;
};

export type TableCellHeading = {
  icon?: DocumentFileTypes;
  isBold?: boolean;
  value: string;
  isEditable?: boolean;
  tagline?: string;
  linkTo?: string;
  savCrmId?: string;
  savCrmNearestId?: string;
  cellKey?: string;
  isConfirmedTotalRent?: boolean;
  hasMatchingRecords?: {
    matches: any;
    indicator: string;
    vertical: Vertical;
  };
  introhiveName?: string;
  introhiveRelationshipStrengthScore?: number;
  introhiveNearestRelationshipStrengthScore?: number;
  introhiveScoreId?: number;
};

export type CompanyTableFormat = {
  value: string;
  savCrmId?: string;
};

export type TableRowComponentProps = {
  row: TableCellProps;
  editInSitu: boolean;
  rowId: string;
  entity: string;
  useContext?: 'DETAILS' | 'RESULTS';
  vertical?: Vertical;
};
export interface LoadingTableProps {
  headers: TableHeaderCellProps[];
  useTheme?: PanelTheme;
  rows?: number;
}

export interface LinkTableCellProps {
  link: string;
  label: string;
  isExternal?: boolean;
}
