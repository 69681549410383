/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createSelector } from 'reselect';
import pathOr from 'ramda/src/pathOr';

import { Vertical } from 'globalConstants';
import { RootState } from 'store/rootReducer';
import { mapStatus } from 'utils/string';
import { formatDate } from 'services/format';
import { BuildingAddress } from 'pages/Details/types';
import { EpcCertificateType } from 'connected/EpcCertificatesPanel/types';

export const getEntityIdSelector = (state: RootState) =>
  state.details.resourceID;

export const getBuildingIdSelector = (state: RootState) =>
  state.details.resourceID;

export const getErrorsSelector = (state: RootState) =>
  state.details.errorMessage;

export const getSummarySelector = (state: RootState) =>
  state.details.building?.summary;

export const getIsFetchingEntity = (state: RootState) =>
  state.details.isFetching;

export const getHasError = (state: RootState) => state.details.hasError;

export const getErrorCode = (state: RootState) => state.details.errorCode;

export const getUpdatingEntity = (state: RootState) =>
  state.details.entityUpdating;

export const getIsUpdating = (state: RootState) => state.details.isSaving;

export const getEntityIdAndVerticalSelector = createSelector(
  getEntityIdSelector,
  (state: RootState) => state.details.vertical,
  (resourceID, vertical) => ({ resourceID, vertical }),
);

export const getEntityToClear = (state: RootState) =>
  state.details.entityToClear;

export const getBuildingIdAndVerticalSelector = createSelector(
  getBuildingIdSelector,
  (state: RootState) => state.details.vertical,
  (buildingID, vertical) => ({ buildingID, vertical }),
);

export const getVerticalSelector = (state: RootState) => state.details.vertical;

export const getAllEntitiesUpdateHistory = (state: RootState) =>
  state.details.updateHistory.entityUpdates;

const mapEntityHistoryValue = (value: string) => {
  let parsedJsonValue;
  try {
    parsedJsonValue = JSON.parse(value);
    if (parsedJsonValue && typeof parsedJsonValue === `object`) {
      return parsedJsonValue;
    }
  } catch (e) {
    return undefined;
  }
  return undefined;
};

export const getEntityUpdateHistory = (state: RootState) => {
  if (!state.details.updateHistory.currentEntity)
    return state.details.updateHistory.currentEntity;

  const historyModel = {
    ...state.details.updateHistory.currentEntity,
    key: state.details.updateHistory.currentEntity.key,
    mostTrusted: state.details.updateHistory.currentEntity.mostTrusted,

    otherSources: state.details.updateHistory.currentEntity.otherSources?.map(
      (item: any) => {
        const otherSourcesParsedCompanies = mapEntityHistoryValue(item.value);

        if (otherSourcesParsedCompanies)
          return {
            ...item,
            parsedCompanies: otherSourcesParsedCompanies,
          };

        return item;
      },
    ),
  };

  const mostTrustedParsedCompanies = mapEntityHistoryValue(
    state.details.updateHistory.currentEntity.mostTrusted?.value,
  );

  if (mostTrustedParsedCompanies)
    historyModel.mostTrusted = {
      ...historyModel.mostTrusted,
      parsedCompanies: mostTrustedParsedCompanies,
    };

  return historyModel;
};

export const isFetchingEntity = (state: RootState) =>
  state.details.updateHistory.isFetching;

export const getBuildingHeaderSelector = createSelector(
  getIsFetchingEntity,
  (state: RootState) => state.details.building,
  (isFetching, building) => {
    const headerAddress =
      building &&
      pathOr<null, BuildingAddress>(
        null,
        ['summary', 'headerAddress'],
        building,
      );

    return headerAddress && !isFetching
      ? {
          subBuilding: headerAddress.subBuilding,
          buildingName: headerAddress.buildingName,
          buildingNumber: headerAddress.buildingNumber,
          street: headerAddress.street,
          town: headerAddress.town,
          postcode: headerAddress.postcode,
        }
      : {};
  },
);

export const getLeaseHeaderSelector = createSelector(
  getIsFetchingEntity,
  (state: RootState) => state.details.lease,
  (isFetching, lease) => {
    const building = lease && lease.relatedBuildings[0];

    return building && !isFetching
      ? {
          subBuilding: building.subBuilding,
          buildingName: building.buildingName,
          buildingNumber: building.buildingNumber,
          street: building.street,
          town: building.town,
          postcode: building.postcode,
          status: lease?.status,
          tenant: lease?.tenant,
          floorPartition: lease?.floorPartition,
        }
      : {};
  },
);

export const getToLetHeaderSelector = createSelector(
  getIsFetchingEntity,
  (state: RootState) => state.details.lease,
  (isFetching, lease) => {
    const building = lease && lease.relatedBuildings[0];

    return building && !isFetching
      ? {
          subBuilding: building.subBuilding,
          buildingName: building.buildingName,
          buildingNumber: building.buildingNumber,
          street: building.street,
          town: building.town,
          postcode: building.postcode,
          status: lease?.status,
        }
      : {};
  },
);

export const getSaleHeaderSelector = createSelector(
  getIsFetchingEntity,
  (state: RootState) => state.details.sale,
  (isFetching, sale) => {
    const building = sale && sale.relatedBuildings[0];

    return building && !isFetching
      ? {
          subBuilding: building.subBuilding,
          buildingName: building.buildingName,
          buildingNumber: building.buildingNumber,
          street: building.street,
          town: building.town,
          postcode: building.postcode,
        }
      : {};
  },
);

export const getPortfolioSaleHeaderSelector = createSelector(
  getIsFetchingEntity,
  (state: RootState) => state.details.sale,
  (isFetching, sale) => {
    return sale && !isFetching
      ? {
          portfolioName: sale.portfolioName,
        }
      : {};
  },
);

export const getSaleSummarySelector = createSelector(
  getIsFetchingEntity,
  (state: RootState) => state.details.sale,
  (isFetching, sale) => {
    return sale && !isFetching
      ? {
          sellers: sale.sellers,
          buyers: sale.buyers,
          status: mapStatus(sale.status),
          ...(sale.achievedPrice && { achievedPrice: sale.achievedPrice }),
          ...(sale.soldDate && { soldDate: sale.soldDate }),
          ...(sale.askingPrice && { askingPrice: sale.askingPrice }),
          ...(sale.offerPrice && { offerPrice: sale.offerPrice }),
          ...(sale.listingDate && { listingDate: sale.listingDate }),
          ...(sale.offerDate && { offerDate: sale.offerDate }),
          ...(sale.achievedPrice && { achievedPrice: sale.achievedPrice }),
        }
      : {};
  },
);

export const getCompanyHeaderSelector = createSelector(
  getIsFetchingEntity,
  (state: RootState) => state.details.company,
  (isFetching, company) => {
    return company && !isFetching
      ? {
          companyName: company.summary.companyName,
          companyAddress: company.summary.companyAddress,
          ...(company.summary.companySector && {
            companySector: company.summary.companySector,
          }),
          ...(company.summary.companyNationality && {
            companyNationality: company.summary.companyNationality,
          }),
        }
      : {};
  },
);

export const getHeaderDetailsSelector = createSelector(
  (state: RootState) => state.details.isFetching,
  (state: RootState) => state.details.vertical,
  (state: RootState) => state.details,
  (isFetching, vertical, details) => {
    const verticalKey = vertical as Vertical.Building | Vertical.Lease;

    return {
      isFetching,
      vertical,
      details: vertical && details[verticalKey],
    };
  },
);

export const getBuildingPageData = (state: RootState) => state.details.building;
export const getLeasePageData = (state: RootState) => state.details.lease;
export const getSalePageData = (state: RootState) => state.details.sale;
export const getCompanyPageData = (state: RootState) => state.details.company;

export const priorExistingRecordsSelector = createSelector(
  (state: RootState) =>
    state.details.building?.sales || state.formBuilder.matchingRecords?.sales,
  (state: RootState) =>
    state.details.building?.leases || state.formBuilder.matchingRecords?.leases,
  (sales, leases) => ({
    sales: sales || [],
    leases: leases?.filter((lease) => !lease.terminationDate) || [],
  }),
);

export const getPropertyInterestType = (state: RootState) =>
  state.propertyInterests.propertyType;

export const getPropertyInterestUse = (state: RootState) =>
  state.propertyInterests.propertyUse;

export const getMostRecentSoldSale = (state: RootState) => {
  if (state.details.building) {
    const { spv, mostRecentSoldSale } = state.details.building;

    const purchaser = mostRecentSoldSale?.buyerCompanies
      ?.map((company) => company.companyName)
      .join(', ');

    return {
      soldDate: mostRecentSoldSale?.soldDate
        ? formatDate(mostRecentSoldSale?.soldDate)
        : '',
      purchaser,
      spv: spv ? spv.companyName : null,
    };
  }
  return null;
};

export const getSelectedPanelFilters = (state: RootState) =>
  state.details.panelFilters;

export const getSelectedLeasePanelFilters = createSelector(
  getSelectedPanelFilters,
  (panelFilters) => (panelFilters && panelFilters.lease) || {},
);

export const getSelectedSalePanelFilters = createSelector(
  getSelectedPanelFilters,
  (panelFilters) => (panelFilters && panelFilters.investment) || [],
);

export const getSelectedToLetPanelFilters = createSelector(
  getSelectedPanelFilters,
  (panelFilters) => (panelFilters && panelFilters.toLet) || {},
);

export const getCompanyDebts = (state: RootState) =>
  state.details?.company?.debts;

export const getSelectedDebtPanelFilters = createSelector(
  getSelectedPanelFilters,
  (panelFilters) => (panelFilters && panelFilters.debts) || [],
);

export const getSelectedEpcPanelFilters = createSelector(
  getSelectedPanelFilters,
  (panelFilters) => panelFilters?.epc || [],
);

export const getSelectedEpcPanelTypeFilter = createSelector(
  getSelectedPanelFilters,
  (panelFilters) =>
    (panelFilters?.epcType?.epcType[0] as EpcCertificateType) ||
    EpcCertificateType.NonDomestic,
);

export const getRecordsToMerge = (state: RootState) =>
  state.details.mergeRecords;

export const getRecordsToCompare = (state: RootState) =>
  state.details.recordsToCompare;

export const isFetchingRecordsToCompare = (state: RootState) =>
  state.details.recordCompareIsFetching;

export const getCompareErrorsSelector = (state: RootState) =>
  state.details.compareErrorMessage;

export const getHasCompareError = (state: RootState) =>
  state.details.compareHasError;

export const getCompareErrorCode = (state: RootState) =>
  state.details.compareErrorCode;

export const getTrustScaleValues = (state: RootState) =>
  state.details.trustscale;

export const getRawEntitiesToCompare = (state: RootState) =>
  state.details.rawEntitiesToCompare;

export type GalleryItemProps = {
  url: string;
  entityId: string;
};

export const getThumbnails = (state: RootState) =>
  state.details.documents
    .filter(({ file }) => file.thumbnail)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .map(({ file, entityId }) => {
      return { url: file.thumbnail!, entityId } as GalleryItemProps;
    });
