export const GLOBAL_MODAL_COPY = {
  closeButton: 'Close',
};

export const FOCUS_TRAP_TABBABLE_ELEMENTS =
  'a[href], button:not([disabled]), textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select';

export const MODAL_ID = {
  FLOOR_DELETE: 'floorDelete',
  EDIT_RECORD: 'editRecord',
  RESULTS_TOAST: 'results-toast',
  COMPANY_DETAILS: 'companyDetails',
  SCHEDULE_QUERY: 'scheduleQuery',
  SCHEDULE_TEAM_QUERY: 'scheduleTeamQuery',
  SCHEDULE_TEAM_QUERY_RESULT: 'scheduleTeamQueryResult',
  DELETE_USER_QUERY: 'deleteUserQuery',
  DELETE_SCHEDULED_QUERY: 'deleteScheduledQuery',
  ADD_DELETE: 'addDelete',
  DEBT_DELETE: 'debtDelete',
  ADD_VALUATION: 'addValuation',
  VALUATION_DELETE: 'valuationDelete',
  BUILDING_DELETE: 'buildingDelete',
  BUILDING_DELETE_FROM_PORTFOLIO: 'buildingDeleteFromPortfolio',
  RELATED_PARTY_EDIT: 'relatedPartyEdit',
  RELATED_PARTY_DELETE: 'relatedPartyDelete',
  FILE_DELETE: 'fileDelete',
  BUILDING_PROTECT: 'buildingProtect',
  BUILDING_PROTECT_STOP: 'buildingProtectStop',
  RELEASE_NOTES: 'releaseNotes',
  COMPANY_OWNERSHIP: 'companyOwnership',
  NEW_RECORD_THANKS: 'newRecordThanks',
  SUPERSEDE_CERTIFICATE: 'supersedeCertificate',
};

export const EDIT_MODAL_TYPE = {
  MOVE_RECORD: 'moveRecord',
  RENEW_LEASE: 'renewLease',
  SPLIT_RECORD: 'splitRecord',
  SPLIT_LEASE: 'splitLease',
  DELETE_RECORD: 'deleteRecord',
  HIDE_RECORD: 'hideRecord',
  EDIT_DEBT: 'editDebt',
  DELETE_DEBT: 'deleteDebt',
  MERGE_RECORDS: 'mergeRecords',
  EDIT_VALUATION: 'editValuation',
  MOVE_DEBT: 'moveDebt',
  MOVE_VALUATION: 'moveValuation',
};
