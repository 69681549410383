import { createSelector } from 'reselect';
import pick from 'ramda/src/pick';

import { RootState } from 'store/rootReducer';
import { SectionProps } from 'components/Filter/types';
import { FILTER_SECTIONS } from 'connected/DocumentPanel/DocumentPanelFilter/constants';

export const getDocumentToDeleteSelector = (state: RootState) => {
  const keys: (keyof typeof state.documentPanel.documentToDelete)[] = [
    'entityId',
    'filename',
  ];

  return pick(keys, state.documentPanel.documentToDelete);
};

export const getDocumentDeleteErrorSelector = (state: RootState) =>
  state.documentPanel.documentToDelete.error;

export const getDocumentIsDeletingSelector = (state: RootState) =>
  state.documentPanel.documentToDelete.isDeleting;

export const getDocumentPanelTableDependenciesSelector = createSelector(
  (state: RootState) => state.documentPanel.isFetching,
  (state: RootState) => state.documentPanel.downloadLink,
  (state: RootState) => state.documentPanel.documentFilters,
  (state: RootState) => state.details.documents,
  (isFetching, downloadLink, documentFilters, documents) => ({
    isFetching,
    downloadLink,
    documentFilters,
    documents,
  }),
);

export const getDocumentFiltersSelector = createSelector(
  (state: RootState) => state.documentPanel.documentFilters,
  (state: RootState) => state.details.documents,
  (selectedFilters, documents) => {
    const activeDocumentTypes = Array.from(
      new Set(documents && documents.map((document) => document.file.type)),
    );

    const groupedFilters: SectionProps[] =
      FILTER_SECTIONS &&
      FILTER_SECTIONS.map((section: SectionProps) => ({
        sectionLabel: section.sectionLabel,
        sectionType: section.sectionType,
        sectionFilters:
          section.sectionFilters &&
          section.sectionFilters.filter((type) =>
            activeDocumentTypes.includes(type.value),
          ),
      }));

    return {
      selectedFilters,
      groupedFilters,
    };
  },
);
