import { GlobalApiFailError } from 'store/actions/types';
import { DocumentPanelActionTypes } from 'connected/DocumentPanel/types';
import { DocumentUploaderActionTypes } from 'connected/DocumentUploader/types';
import { NewSaleActionTypes } from 'pages/NewSale/types';
import { NewLeaseActionTypes } from 'pages/NewLease/types';
import { EditRecordActionTypes } from 'store/actions/editRecordActions';
import { Vertical } from 'globalConstants';
import { QuickSearchResult } from 'connected/QuickSearch/types';
import { NotesActionTypes } from 'connected/NotesPanel/types';
import { PollingUpdateActionTypes } from 'store/actions/pollingUpdateActions';

import {
  LocationProps,
  GoogleCoordLatLngLiteral,
} from 'components/GoogleMap/types';
import { DebtsActionTypes } from 'connected/DebtPanel/types';
import { RelationRole } from 'services/types';
import { AuditLogProps } from 'connected/AuditLogPanel/types';
import {
  Valuation,
  ValuationsActionTypes,
} from 'connected/ValuationPanel/types';
import { EpcCertificate } from 'connected/EpcCertificatesPanel/types';
import {
  AvailabilityStatus,
  IndividualCompany,
} from '../../connected/InvestmentPanel/types';
import { STACKING_PLAN_EVENT_TYPES } from './Building/StackingPlan/constants';

export enum DetailsActionTypes {
  DETAILS_API_SUCCESS = 'DETAILS_API_SUCCESS',
  DETAILS_FETCH = 'DETAILS_FETCH',
  DETAILS_API_FAIL = 'DETAILS_API_FAIL',
  DETAILS_RESET = 'DETAILS_RESET',
  MAP_PIN_DETAILS_FETCH = 'MAP_PIN_DETAILS_FETCH',
  MAP_PIN_DETAILS_API_SUCCESS = 'MAP_PIN_DETAILS_API_SUCCESS',
  MAP_PIN_DETAILS_API_FAIL = 'MAP_PIN_DETAILS_API_FAIL',
  MAP_PIN_DETAILS_RESET = 'MAP_PIN_DETAILS_RESET',
  UPDATE_DETAILS = 'UPDATE_DETAILS',
  UPDATE_DETAILS_API_SUCCESS = 'UPDATE_DETAILS_API_SUCCESS',
  UPDATE_DETAILS_API_FAIL = 'UPDATE_DETAILS_API_FAIL',
  DETAILS_REFRESH = 'DETAILS_REFRESH',
  UPDATE_BUILDING = 'UPDATE_BUILDING',
  GET_BUILDING_LEASES_SALES = 'GET_BUILDING_LEASES_SALES',
  GET_BUILDING_LEASES_SALES_SUCCESS = 'GET_BUILDING_LEASES_SALES_SUCCESS',
  GET_COMPANY_PROPERTY_INTERESTS = 'GET_COMPANY_PROPERTY_INTERESTS',
  PROPERTY_INTERESTS_UPDATE_USE = 'PROPERTY_INTERESTS_UPDATE_USE',
  PROPERTY_INTERESTS_UPDATE_TYPE = 'PROPERTY_INTERESTS_UPDATE_TYPE',
  ENTITY_TO_CLEAR = 'ENTITY_TO_CLEAR',
  DELETE_BUILDING_FROM_PORTFOLIO = 'DELETE_BUILDING_FROM_PORTFOLIO',
  DELETE_BUILDING_FROM_PORTFOLIO_API_FAIL = 'DELETE_BUILDING_FROM_PORTFOLIO_API_FAIL',
  DELETE_BUILDING_FROM_PORTFOLIO_API_SUCCESS = 'DELETE_BUILDING_FROM_PORTFOLIO_API_SUCCESS',
  DELETE_BUILDING = 'DELETE_BUILDING',
  DELETE_BUILDING_API_FAIL = 'DELETE_BUILDING_API_FAIL',
  DELETE_BUILDING_API_SUCCESS = 'DELETE_BUILDING_API_SUCCESS',
  GET_UPDATE_HISTORY = 'GET_UPDATE_HISTORY',
  GET_UPDATE_HISTORY_API_FAIL = 'GET_UPDATE_HISTORY_API_FAIL',
  GET_UPDATE_HISTORY_API_SUCCESS = 'GET_UPDATE_HISTORY_API_SUCCESS',
  DELETE_FLOOR_ACTION = 'DELETE_FLOOR_ACTION',
  DELETE_FLOOR_API_FAIL = 'DELETE_FLOOR_API_FAIL',
  DELETE_FLOOR_API_SUCCESS = 'DELETE_FLOOR_API_SUCCESS',
  UPDATE_PANEL_FILTERS = 'UPDATE_PANEL_FILTERS',
  DELETE_COMPANY = 'DELETE_COMPANY',
  DELETE_COMPANY_API_FAIL = 'DELETE_COMPANY_API_FAIL',
  DELETE_COMPANY_API_SUCCESS = 'DELETE_COMPANY_API_SUCCESS',
  MOVE_ENTITY = 'MOVE_ENTITY',
  MOVE_ENTITY_SUCCESS = 'MOVE_ENTITY_SUCCESS',
  MOVE_ENTITY_FAIL = 'MOVE_ENTITY_FAIL',
  RENEW_ENTITY = 'RENEW_ENTITY',
  RENEW_ENTITY_SUCCESS = 'RENEW_ENTITY_SUCCESS',
  RENEW_ENTITY_FAIL = 'RENEW_ENTITY_FAIL',
  SPLIT_ENTITY = 'SPLIT_ENTITY',
  SPLIT_ENTITY_SUCCESS = 'SPLIT_ENTITY_SUCCESS',
  SPLIT_ENTITY_FAIL = 'SPLIT_ENTITY_FAIL',
  HIDE_ENTITY = 'HIDE_ENTITY',
  HIDE_ENTITY_SUCCESS = 'HIDE_ENTITY_SUCCESS',
  HIDE_ENTITY_FAIL = 'HIDE_ENTITY_FAIL',
  DELETE_ENTITY = 'DELETE_ENTITY',
  DELETE_ENTITY_SUCCESS = 'DELETE_ENTITY_SUCCESS',
  DELETE_ENTITY_FAIL = 'DELETE_ENTITY_FAIL',
  ADD_WATCH_RECORD = 'ADD_WATCH_RECORD',
  ADD_WATCH_RECORD_SUCCESS = 'ADD_WATCH_RECORD_SUCCESS',
  ADD_WATCH_RECORD_FAIL = 'ADD_WATCH_RECORD_FAIL',
  DELETE_WATCH_RECORD = 'DELETE_WATCH_RECORD',
  DELETE_WATCH_RECORD_SUCCESS = 'DELETE_WATCH_RECORD_SUCCESS',
  DELETE_WATCH_RECORD_FAIL = 'DELETE_WATCH_RECORD_FAIL',
  RECORDS_TO_MERGE = 'RECORDS_TO_MERGE',
  MERGE_RECORDS = 'MERGE_RECORDS',
  MERGE_RECORDS_SUCCESS = 'MERGE_RECORDS_SUCCESS',
  MERGE_RECORDS_FAIL = 'MERGE_RECORDS_FAIL',
  COMPARE_RECORDS = 'COMPARE_RECORDS',
  COMPARE_RECORDS_SUCCESS = 'COMPARE_RECORDS_SUCCESS',
  COMPARE_RECORDS_FAIL = 'COMPARE_RECORDS_FAIL',
  COMPARE_RECORDS_RESET = 'COMPARE_RECORDS_RESET',
  PROTECT_BUILDING = 'PROTECT_BUILDING',
  PROTECT_BUILDING_API_FAIL = 'PROTECT_BUILDING_API_FAIL',
  PROTECT_BUILDING_API_SUCCESS = 'PROTECT_BUILDING_API_SUCCESS',
}

export type UpdateDetailsAction = {
  type: DetailsActionTypes.UPDATE_DETAILS;
  payload: any;
  meta: 'DETAILS' | 'RESULTS';
};

export type ClearEntityAction = {
  type: DetailsActionTypes.ENTITY_TO_CLEAR;
  payload: {
    entityName: string;
    entityId: string;
    key: string;
  };
};

export type DetailsFetchAction = {
  type: DetailsActionTypes.DETAILS_FETCH;
  payload: ResourceIdentifiers;
};

export type DetailsApiSuccessAction = {
  type: DetailsActionTypes.DETAILS_API_SUCCESS;
  payload: DetailsPayload;
};

export type DetailsApiFailAction = {
  type: DetailsActionTypes.DETAILS_API_FAIL;
  payload: GlobalApiFailError;
};

export type DetailsResetAction = {
  type: DetailsActionTypes.DETAILS_RESET;
};

export type UpdateDetailsApiSuccessAction = {
  type: DetailsActionTypes.UPDATE_DETAILS_API_SUCCESS;
};

export type UpdateDetailsApiFailAction = {
  type: DetailsActionTypes.UPDATE_DETAILS_API_FAIL;
  payload: GlobalApiFailError;
};

export type DetailsRefreshAction = {
  type: DetailsActionTypes.DETAILS_REFRESH;
};

export type UpdateBuildingAction = {
  type: DetailsActionTypes.UPDATE_BUILDING;
  payload: {
    building: BuildingRecord;
  };
};

export type GetBuildingLeasesSalesAction = {
  type: DetailsActionTypes.GET_BUILDING_LEASES_SALES;
  payload: ResourceIdentifiers;
};

export type GetBuildingLeasesSalesSuccessAction = {
  type: DetailsActionTypes.GET_BUILDING_LEASES_SALES_SUCCESS;
  payload: {
    mostRecentSoldSale?: SaleRecord;
    landlords?: Company[];
    spv?: Company;
  };
};

export type UpdateTypePropertyInterestsAction = {
  type: DetailsActionTypes.PROPERTY_INTERESTS_UPDATE_TYPE;
  payload: string;
};

export type UpdateUsePropertyInterestsAction = {
  type: DetailsActionTypes.PROPERTY_INTERESTS_UPDATE_USE;
  payload: string;
};

export type GetMapPinDetailsFetchAction = {
  type: DetailsActionTypes.MAP_PIN_DETAILS_FETCH;
  payload: ResourceIdentifiers;
};

export type GetMapPinDetailsSuccessAction = {
  type: DetailsActionTypes.MAP_PIN_DETAILS_API_SUCCESS;
  payload: MapPinDetailsPayload;
};

export type GetMapPinDetailsFailAction = {
  type: DetailsActionTypes.MAP_PIN_DETAILS_API_FAIL;
  payload: GlobalApiFailError;
};

export type GetUpdateHistoryAction = {
  type: DetailsActionTypes.GET_UPDATE_HISTORY;
  payload: GetUpdateHistoryPayload;
};

export type GetUpdateHistorySuccessAction = {
  type: DetailsActionTypes.GET_UPDATE_HISTORY_API_SUCCESS;
  payload: UpdateHistoryPayload;
};

export type GetUpdateHistoryFailAction = {
  type: DetailsActionTypes.GET_UPDATE_HISTORY_API_FAIL;
  payload: GlobalApiFailError;
};

export type MoveEntityAction = {
  type: DetailsActionTypes.MOVE_ENTITY;
  payload: MoveEntityPayload;
};

export type MoveEntitySuccessAction = {
  type: DetailsActionTypes.MOVE_ENTITY_SUCCESS;
};

export type MoveEntityFailAction = {
  type: DetailsActionTypes.MOVE_ENTITY_FAIL;
  payload: GlobalApiFailError;
};

export type RenewEntityAction = {
  type: DetailsActionTypes.RENEW_ENTITY;
  payload: RenewEntityPayload;
};

export type RenewEntitySuccessAction = {
  type: DetailsActionTypes.RENEW_ENTITY_SUCCESS;
};

export type RenewEntityFailAction = {
  type: DetailsActionTypes.RENEW_ENTITY_FAIL;
  payload: GlobalApiFailError;
};

export type SplitEntityAction = {
  type: DetailsActionTypes.SPLIT_ENTITY;
  payload: SplitEntityPayload;
};

export type SplitEntitySuccessAction = {
  type: DetailsActionTypes.SPLIT_ENTITY_SUCCESS;
};

export type SplitEntityFailAction = {
  type: DetailsActionTypes.SPLIT_ENTITY_FAIL;
  payload: GlobalApiFailError;
};

export type HideEntityAction = {
  type: DetailsActionTypes.HIDE_ENTITY;
  payload: HideEntityPayload;
};

export type HideEntitySuccessAction = {
  type: DetailsActionTypes.HIDE_ENTITY_SUCCESS;
};

export type HideEntityFailAction = {
  type: DetailsActionTypes.HIDE_ENTITY_FAIL;
  payload: GlobalApiFailError;
};

export type DeleteEntityAction = {
  type: DetailsActionTypes.DELETE_ENTITY;
  payload: DeleteEntityPayload;
};

export type DeleteEntitySuccessAction = {
  type: DetailsActionTypes.DELETE_ENTITY_SUCCESS;
};

export type DeleteEntityFailAction = {
  type: DetailsActionTypes.DELETE_ENTITY_FAIL;
  payload: GlobalApiFailError;
};

export type MapPinDetailsReset = {
  type: DetailsActionTypes.MAP_PIN_DETAILS_RESET;
};

export type AddWatchRecordAction = {
  type: DetailsActionTypes.ADD_WATCH_RECORD;
  payload: WatchPayload;
};

export type AddWatchRecordSuccessAction = {
  type: DetailsActionTypes.ADD_WATCH_RECORD_SUCCESS;
};

export type AddWatchRecordFailAction = {
  type: DetailsActionTypes.ADD_WATCH_RECORD_FAIL;
  payload: GlobalApiFailError;
};

export type DeleteWatchRecordAction = {
  type: DetailsActionTypes.DELETE_WATCH_RECORD;
  payload: DeleteWatchPayload;
};

export type DeleteWatchRecordSuccessAction = {
  type: DetailsActionTypes.DELETE_WATCH_RECORD_SUCCESS;
};

export type DeleteWatchRecordFailAction = {
  type: DetailsActionTypes.DELETE_WATCH_RECORD_FAIL;
  payload: GlobalApiFailError;
};

export type RecordsToMergeAction = {
  type: DetailsActionTypes.RECORDS_TO_MERGE;
  payload: RecordsToMergePayload;
};

export type MergeRecordsAction = {
  type: DetailsActionTypes.MERGE_RECORDS;
  payload: DetailsPayload;
};

export type MergeRecordsSuccessAction = {
  type: DetailsActionTypes.MERGE_RECORDS_SUCCESS;
};

export type MergeRecordsFailAction = {
  type: DetailsActionTypes.MERGE_RECORDS_FAIL;
  payload: GlobalApiFailError;
};

export type CompareRecordsAction = {
  type: DetailsActionTypes.COMPARE_RECORDS;
  payload: CompareRecordsPayload;
};

export type CompareRecordsSuccessAction = {
  type: DetailsActionTypes.COMPARE_RECORDS_SUCCESS;
  payload: DetailsPayload;
};

export type CompareRecordsFailAction = {
  type: DetailsActionTypes.COMPARE_RECORDS_FAIL;
  payload: GlobalApiFailError;
};

export type CompareRecordsResetAction = {
  type: DetailsActionTypes.COMPARE_RECORDS_RESET;
};

export type ResourceIdentifiers = {
  id: string;
  vertical: Vertical;
  entity?: QuickSearchResult;
};

export type ResourceApiEndpoint = {
  [key in Vertical]: string;
};

export const RESOURCE_API_ENDPOINT: ResourceApiEndpoint = {
  [Vertical.Building]: 'building',
  [Vertical.Company]: 'company',
  [Vertical.Sale]: 'sale',
  [Vertical.Lease]: 'lease',
  [Vertical.ToLet]: 'lease',
  [Vertical.PortfolioSale]: 'sale',
};

export type Company = {
  companyId: string;
  companyRegistrationNumber?: string;
  companyName: string;
  equity?: number;
  investorOccupier?: string;
  sector?: string;
  country?: string;
};

export type DetailsDocuments = {
  entityId: string;
  file: {
    size: string;
    name: string;
    type: string;
    thumbnail?: string;
  };
  uploaded: {
    by: string;
    datetime: string;
  };
};

export type Instructions = {
  id: string;
  clientName: string;
  description?: string;
  endDate?: string;
  startDate: string;
  officeDepartmentCode: number;
  officeDepartmentName: string;
  ownerEmail: string;
  ownerName: string;
  savRef?: string;
  serviceLines: string[];
  jobCategories: string[];
  status?: string;
  __entityName__: string;
};

export type Notes = {
  entityId: string;
  __entityName__: string;
  id: string;
  creator: string;
  noteDate: string;
  description: string;
  isPrivate: boolean;
  isExportable: boolean;
};

export type DealItem = {
  title: string;
  id: string;
  linkId?: string;
  link?: string;
  list: { title: string; body: string }[];
};

export type OtherLeaseItem = {
  title: string;
  items: DealItem[];
};

export type DealDetails = {
  title: string;
  subtitle?: string;
  demiseParentId?: string;
  leases: DealItem[];
  otherLeasesContent?: string;
  otherLeases?: OtherLeaseItem[];
  standaloneLeases?: DealItem[];
  leaseArea?: number;
  tenant?: string;
  startDate?: string;
  endDate?: string;
  totalLeases?: number;
};

export type NextEvent = {
  type: STACKING_PLAN_EVENT_TYPES;
  date: string;
};

export type LeaseRecord = {
  [key: string]: any;
  leaseId: string;
  availabilityStatus: string;
  primaryUse?: string;
  type?: string;
  landlords?: Company[];
  landlordAgents?: Company[];
  tenant?: IndividualCompany;
  readyToOccupyDate?: string;
  terminationDate?: string;
  minimumLettableArea?: number;
  maximumLettableArea?: number;
  floor?: string;
  partition?: string;
  totalArea?: number;
  totalRent?: number;
  rent?: number;
  usage?: string;
  leaseType?: string;
  startDate?: string;
  breakOptions?: BreakOption[];
  rentReviews?: RentReview[];
  endDate: string;
  serviceCharge?: number;
  askingRent?: number;
  dealIsPrivate?: boolean;
  description?: string;
  offeredRent?: number;
  achievedRent?: number;
  floors?: FloorAndPartition[];
  nextRentReview?: string;
  nextBreakDate?: string;
  percentageTotalArea?: number;
  percentageOfAllLeases?: number;
  landlordLeaseId?: string;
  isUnknownSpace?: boolean;
  isMissingFloor?: boolean;
  nextEvent?: NextEvent;
  useClauses?: string[];
  isDisputed?: boolean;
  docsCount?: number;
  isHidden?: boolean;
};

export type SaleRecord = {
  saleId: string;
  dealIsPrivate?: boolean;
  availabilityStatus: AvailabilityStatus;
  date?: string;
  sellerCompanies?: Company[];
  buyerCompanies?: Company[];
  buyersSPV?: Company[];
  reversionaryYield?: number;
  netInitialYield?: number;
  equivalentYield?: number;
  price?: number;
  totalRent?: number;
  waultc?: number;
  purchaserCosts?: number;
  capitalValue?: number;
  soldDate?: string;
  achievedPrice?: number;
  isPortfolio?: boolean;
  isDisputed?: boolean;
  docsCount?: number;
  freeholder?: IndividualCompany;
  isConfidential?: boolean;
  sortingDate?: Date;
  isHidden?: boolean;
  isDeleted?: boolean;
  hasMatchingRecords?: {
    matches: SaleRecord[];
    indicator: string;
    vertical: Vertical;
  };
};

export type BuildingRecord = {
  buildingId: string;
  buildingName: string;
  buildingNumber: string;
  buildingsOtherNames?: string[];
  buildingAddress: string;
  street: string;
  town: string;
  postcode: string;
  country: string;
  uprn: string;
  market: string;
  nia: string;
  entityName: string;
  entityId: string;
  coords?: GoogleCoordLatLngLiteral;
  primaryUse?: string;
  totalArea?: number;
  mostRecentSoldSale?: SaleRecord;
  landlords?: CompanyRecord[];
  unitSale?: {
    id: string;
    entityName: string;
  };
  floors?: Floor[];
  headerAddress?: BuildingAddress;
  subBuilding?: string;
  epcRating?: string;
};

export type CompanyRecord = {
  companyId: string;
  companyName: string;
  companyRegistrationNumber?: string;
  companyAddress: string;
  companySector?: string;
  companyNationality?: string;
  entityName: string;
  entityId: string;
  isPrivate?: boolean | null;
  companyType?: string;
  companyEquity?: number;
  label?: string;
};

export type CompanyDebtRecord = {
  company: string;
  borrowers?: any;
  debt: {
    debtAmount: number;
    isVoided?: boolean;
    interestRate?: number;
    baseRate?: number;
    maturityDate?: string;
    notes?: string;
    buildings?: BuildingRecord[];
    id: string;
    __entityName__: string;
  };
  relationRole?: string;
  role?: string;
  entityId: string;
  __entityName__: string;
};

export type DebtRecord = {
  id: string;
  lenders: CompanyRecord[];
  borrowers: CompanyRecord[];
  debtAmount: number;
  isVoided?: boolean;
  interestRate?: number;
  baseRate?: number;
  startDate?: string;
  maturityDate?: string;
  notes?: string;
  entityId: string;
  __entityName__: string;
};

export type DetailsSummary = BuildingRecord;

export type BreakOption = {
  id: string;
  leaseId: string;
  type: string;
  date: string;
  hasRedevelopmentBreakClause?: boolean;
  hasRollingBreakClause?: boolean;
  removed?: boolean;
  globalPenalty?: number;
  additionalMonthsRentFree?: number;
  monthsRentPenalty?: number;
  additionalMonthsHalfRent?: number;
  removedDate?: string;
  __entityName__: string;
  isVoided?: boolean;
};

export type RentReview = {
  id: string;
  leaseId: string;
  upwardOnly?: boolean;
  timeOfTheEssenceClauseInvokable?: boolean;
  reviewType: string;
  reviewDate: string;
  rentFrom?: string;
  rentTo?: string;
  collar?: string;
  collarValue?: number;
  cap?: string;
  capValue?: number;
  hypotheticalTerm?: number;
  __entityName__: string;
  isVoided?: boolean;
};

export type BuildingState = {
  summary: BuildingRecord;
  sales?: SaleRecord[];
  leases?: LeaseRecord[];
  floors?: Floor[];
  geom?: {
    latLng?: google.maps.LatLng | google.maps.LatLngLiteral;
    name?: string;
  };
  status?: string;
  isCanonicalAddress?: boolean;
  canonicalBuildingId?: string;
  mostRecentSoldSale?: SaleRecord;
  landlords?: Company[];
  spv?: Company;
  relatedParties?: RoleState[];
  auditLog?: AuditLogProps[];
  notes?: Notes[];
  otherAddresses?: BuildingAddress[];
  otherNames?: string[];
  headerAddress?: BuildingAddress;
  instructions?: Instructions[];
  primaryUse?: string;
  entityName?: string;
  entityId?: string;
  buildingDemolished?: boolean;
  debts?: DebtRecord[];
  valuations?: Valuation[];
  isDeleted?: boolean;
  deletedReason?: string;
  wault?: number;
  waultc?: number;
  totalArea?: number;
  avgRent?: number | string;
  isProtected?: boolean;
  listingType?: string;
  epcCertificates?: EpcCertificate[];
};

export type LeaseState = {
  id: string;
  status: string;
  type: string;
  relatedBuildings: BuildingRecord[];
  landlord?: Pick<Company, 'companyId' | 'companyName'>;
  tenant?: Pick<Company, 'companyId' | 'companyName'>;
  breakOptions?: BreakOption[];
  rentReviews?: RentReview[];
  askingRent?: number;
  offeredRent?: number;
  achievedRent?: number;
  rentFreeMonths?: number;
  rentFreeMonthsTotal?: number;
  halfRentFreeMonths?: number;
  capitalContribution?: number;
  entityName?: string;
  entityId?: string;
  isConfidential: string;
  isDisputed: boolean;
  geom?: {
    latLng?: google.maps.LatLng | google.maps.LatLngLiteral;
    name?: string;
  };
  dealDetails?: DealDetails;
  demiseParentId?: string;
  relatedParties?: RoleState[];
  auditLog?: AuditLogProps[];
  notes?: Notes[];
  floorPartition?: any;
  floors?: Floor[];
  totalArea?: number;
  startDate: string;
  endDate: string;
  isHidden?: boolean;
  isDeleted: boolean;
  deletedReason?: string;
  terminationDate?: string;
  terminationReason?: string;
  readyToOccupyDate?: string;
  onMarketDate?: string;
  offMarketDate?: string;
  contractSignedDate?: string;
  headsOfTermsAgreedDate?: string;
  instructionDate?: string;
};

export type RoleState = {
  __entityName__: string;
  key: string;
  role: RelationRole;
  companyName: string;
  companyId: string;
  name: string;
  surname: string;
  email?: string;
  telephone?: string;
  entityId: string;
  entityName: string;
  employeeEntityId?: string;
  employeeEntityName?: string;
  employeePhoneEntityId?: string;
  savCrmId?: string;
  savCrmNearestId?: string;
  introhiveName?: string;
  introhiveScoreId?: number;
  introhiveRelationshipStrengthScore?: number;
  introhiveNearestRelationshipStrengthScore?: number;
};

export type LongLeasesState = {
  startDate?: string;
  endDate?: string;
  gearing?: boolean;
  gearingProportion?: number;
  gearingFixed?: number;
  gearingBasisType?: string;
  gearingReviewFrequency?: number;
};

export type SaleState = {
  buildingId?: string;
  id: string;
  status: string;
  relatedBuildings: BuildingRecord[];
  sellers?: Pick<Company, 'companyId' | 'companyName'>;
  buyers?: Pick<Company, 'companyId' | 'companyName'>;
  buyersSPV?: Pick<Company, 'companyId' | 'companyName'>;
  achievedPrice?: number;
  soldDate?: Date;
  askingPrice?: number;
  offerPrice?: number;
  listingDate?: Date;
  offerDate?: Date;
  entityName?: string;
  entityId?: string;
  isConfidential: string;
  isDisputed: boolean;
  geom?: {
    latLng?: google.maps.LatLng | google.maps.LatLngLiteral;
    name?: string;
  };
  tenure?: string;
  totalArea?: number;
  totalAreaAtSale?: number;
  offeredPrice?: number;
  salePrice?: number;
  sortingDate?: Date;
  availabilityStatus?: string;
  relatedParties?: RoleState[];
  auditLog?: AuditLogProps[];
  notes?: Notes[];
  portfolioName?: string;
  numberOfBuildings?: number;
  isPortfolio?: boolean;
  freeholder?: IndividualCompany;
  isHidden?: boolean;
  isDeleted: boolean;
  deletedReason?: string;
  withdrawnDate?: string;
  underOfferDate?: string;
  readyToOccupyDate?: string;
  reversionaryYield?: number;
  rentalTopUpAmount?: number | string;
  longLeases?: LongLeasesState[];
  rentalIncome?: number | string;
};

export type PropertyOwnershipProps = {
  label: string;
  type: 'ownership' | 'occupation' | 'owner-occupier';
  count: number;
  total: number;
  buildings: LocationProps[];
  use: string;
};

export type StructureProps = {
  companyId: string;
  companyName: string;
  ownershipStake?: string;
  ownershipStakePercent?: number;
  savCrmId: string;
  savCrmNearestId?: string;
  introhiveCompanyId?: number;
  introhiveName?: string;
  introhiveRelationshipStrengthScore?: number;
  introhiveCompanyCapitalScore?: number;
  introhiveScoreId?: number;
};

export type CompanyStructure = {
  children: CompanyStructure[];
  stakeholders: StructureProps[];
};

export type CompanyParentParentState = {
  id: string;
  name: string;
  isPrivate: boolean;
  countryOfOrigin: string;
};

export type CompanyParentState = {
  childCompanyId: string;
  ownershipStake: string;
  parentCompanyId: CompanyParentParentState;
};

export type CompanyState = {
  summary: CompanyRecord;
  sales?: SaleRecord[];
  leases?: LeaseRecord[];
  geom?: {
    latLng?: google.maps.LatLng | google.maps.LatLngLiteral;
    name?: string;
  };
  propertyOwnership?: PropertyOwnershipProps[];
  relatedParties?: RoleState[];
  auditLog?: AuditLogProps[];
  notes?: Notes[];
  structure?: CompanyStructure[];
  companyClosed: boolean;
  isPrivate?: boolean;
  entityName?: string;
  entityId?: string;
  debts?: CompanyDebtRecord[];
  tradingAsNames?: string[];
  previousCompanyNames?: string[];
  introhiveCompanyId?: number;
  introhiveName?: string;
  introhiveScoreId?: number;
  introhiveRelationshipStrengthScore?: number;
  introhiveCompanyCapitalScore?: number;
  parents: CompanyParentState[];
};

export type DetailsPayload = {
  vertical: Vertical;
  [Vertical.Building]?: BuildingState;
  [Vertical.Lease]?: LeaseState;
  [Vertical.ToLet]?: LeaseState;
  [Vertical.Company]?: CompanyState;
  [Vertical.Sale]?: SaleState;
  [Vertical.PortfolioSale]?: SaleState;
  records?: LeaseState[] | SaleState[];
  documents: DetailsDocuments[];
  trustscale?: TrustScaleResultPayload[];
  rawEntitiesToCompare?: unknown[];
};

export type MapPinDetailsPayload = {
  [Vertical.Building]?: {
    summary: BuildingRecord & {
      totalArea?: number;
      latestLeaseEnd?: string;
      earliestLeaseStart?: string;
    };
    leases: LeaseRecord[];
    sales: SaleRecord[];
  };
};

export type GetUpdateHistoryPayload = {
  resourceId: string;
  vertical: string;
  entity: string;
};

export type UpdateHistoryResponse = {
  mostTrustedAtUpdate: boolean;
  source: string;
  subSource?: string;
  updatedAt: string;
  value: string | number | Date | boolean;
  parsedCompanies?: UpdateHistoryCompanyReference[];
};

export type UpdateHistoryCompanyReference = {
  companyId: string;
  companyName: string;
  relationRole: string;
  nationality: string;
  companyRegistrationNumber: string;
};

export type EntityHistoryPayload = {
  key: string;
  mostTrusted: UpdateHistoryResponse;
  otherSources: UpdateHistoryResponse[];
};

export type UpdateHistoryPayload = {
  entity: string;
  history: {
    [key: string]: EntityHistoryPayload;
  };
};

export type MoveEntityPayload = {
  vertical: Vertical;
  buildingId?: string;
  leaseIds?: string[];
  saleIds?: string[];
  relatedBuildings?: {
    buildingId?: string;
  }[];
  debtId?: string;
};

export type RenewEntityPayload = {
  vertical: Vertical;
  buildingId: string;
  leaseIds?: string[];
  saleIds?: string[];
};

export type SplitEntityPayload = {
  leaseId: string;
  demises: any;
};

export type DeleteEntityPayload = {
  vertical: Vertical;
  reason: string;
  leaseId?: string;
  saleId?: string;
  isDeleted: boolean;
};

export type HideEntityPayload = {
  vertical: Vertical;
  reason: string;
  leaseId?: string;
  saleId?: string;
  isHidden: boolean;
};

export type WatchPayload = {
  entity: string;
  id: string;
};

export type DeleteWatchPayload = {
  watchId: string;
};

export type Partition = {
  id: string;
  partitionId: string;
  partitionName: string;
  areaSqFt?: number;
  measurementStandard?: string;
  isHistoric?: boolean;
};

export type Floor = {
  [key: string]: any;
  floorId: string;
  floorName: string;
  floorLevel: number;
  partitions: Partition[];
  areaSqFt?: number;
  measurementStandard?: string;
  hasLeases?: boolean;
  isCanonical?: boolean;
  entityName: string;
};

export type FloorAndPartition = {
  floorId: string;
  floorName: string;
  floorLevel?: number;
  partitionId: string;
  partitionName: string;
};

export type BuildingAddress = {
  buildingId: string;
  buildingName: string;
  buildingNumber: string;
  buildingAddress?: string;
  street: string;
  town: string;
  postcode: string;
  country?: string;
  isCanonicalAddress?: boolean;
  subBuilding?: string;
  isProtected?: boolean;
};

export type DeleteBuildingFromPortfolioPayload = {
  id: string;
  entity: string;
};

export type DeleteBuildingPayload = {
  id: string;
  entity: string;
};

export type BuildingProtectPayload = {
  buildingId: string;
  isProtected: boolean;
};

export type DeleteCompanyPayload = {
  companyId: string;
  replacementCompanyId?: string;
  replacementCompanyRegistrationNumber?: string;
};

export type DeleteFloorPayload = {
  id: string;
  entity: string;
};

export type BuildingDeleteFromPortfolioAction = {
  type: DetailsActionTypes.DELETE_BUILDING_FROM_PORTFOLIO;
  payload: DeleteBuildingFromPortfolioPayload;
};

export type BuildingDeleteAction = {
  type: DetailsActionTypes.DELETE_BUILDING;
  payload: DeleteBuildingPayload;
};

export type BuildingProtectAction = {
  type: DetailsActionTypes.PROTECT_BUILDING;
  payload: BuildingProtectPayload;
};

export type BuildingProtectApiFailAction = {
  type: DetailsActionTypes.PROTECT_BUILDING_API_FAIL;
  payload: GlobalApiFailError;
};

export type BuildingProtectSuccessAction = {
  type: DetailsActionTypes.PROTECT_BUILDING_API_SUCCESS;
};

export type DeleteFloorAction = {
  type: DetailsActionTypes.DELETE_FLOOR_ACTION;
  payload: DeleteFloorPayload;
};

export type BuildingDeleteFromPortfolioApiFailAction = {
  type: DetailsActionTypes.DELETE_BUILDING_FROM_PORTFOLIO_API_FAIL;
  payload: GlobalApiFailError;
};

export type BuildingDeleteFromPortfolioSuccessAction = {
  type: DetailsActionTypes.DELETE_BUILDING_FROM_PORTFOLIO_API_SUCCESS;
};

export type BuildingDeleteApiFailAction = {
  type: DetailsActionTypes.DELETE_BUILDING_API_FAIL;
  payload: GlobalApiFailError;
};

export type BuildingDeleteSuccessAction = {
  type: DetailsActionTypes.DELETE_BUILDING_API_SUCCESS;
};

export type CompanyDeleteAction = {
  type: DetailsActionTypes.DELETE_COMPANY;
  payload: DeleteCompanyPayload;
};
export type CompanyDeleteApiFailAction = {
  type: DetailsActionTypes.DELETE_COMPANY_API_FAIL;
  payload: GlobalApiFailError;
};
export type CompanyDeleteSuccessAction = {
  type: DetailsActionTypes.DELETE_COMPANY_API_SUCCESS;
};

export type FloorDeleteApiFailAction = {
  type: DetailsActionTypes.DELETE_FLOOR_API_FAIL;
  payload: GlobalApiFailError;
};

export type FloorDeleteSuccessAction = {
  type: DetailsActionTypes.DELETE_FLOOR_API_SUCCESS;
};

export type UpdatePanelFilterPayload = {
  [panelName: string]: { [groupKey: string]: string[] };
};

export type UpdatePanelFilterAction = {
  type: DetailsActionTypes.UPDATE_PANEL_FILTERS;
  payload: UpdatePanelFilterPayload;
};

export type RecordsToMergePayload = {
  lease?: string[];
  sale?: string[];
};

export type EntityPayload = {
  entity: string;
  property: string;
  id: string;
};

export type MergeRecordsPayload = DetailsPayload & {
  saleId?: string;
  leaseId?: string;
  otherMergedSaleIds?: string[];
  otherMergedLeaseIds?: string[];
  clearProperties?: EntityPayload[];
};

export type CompareRecordsPayload = {
  vertical: Vertical;
  recordId: string[];
};

export type TrustScaleResultPayload = {
  column: string;
  entityId: string;
  propertyName: string;
  score: number;
  source: string;
  sources: TrustScaleResultSourcePayload[];
  table: string;
};

export type TrustScaleResultSourcePayload = {
  entityId: string;
  mostTrusted: boolean;
  source: string;
};

export type TrustScaleResultType = {
  entityId: string;
  propertyName: string;
  source: string;
};

export type DetailsActions =
  | DetailsFetchAction
  | DetailsApiSuccessAction
  | DetailsApiFailAction
  | DetailsResetAction
  | UpdateDetailsAction
  | UpdateDetailsApiSuccessAction
  | UpdateDetailsApiFailAction
  | UpdateBuildingAction
  | GetBuildingLeasesSalesAction
  | GetBuildingLeasesSalesSuccessAction
  | UpdateTypePropertyInterestsAction
  | UpdateUsePropertyInterestsAction
  | GetMapPinDetailsFetchAction
  | GetMapPinDetailsSuccessAction
  | GetMapPinDetailsFailAction
  | MapPinDetailsReset
  | ClearEntityAction
  | BuildingDeleteFromPortfolioAction
  | BuildingDeleteFromPortfolioApiFailAction
  | BuildingDeleteFromPortfolioSuccessAction
  | BuildingDeleteAction
  | BuildingDeleteApiFailAction
  | BuildingDeleteSuccessAction
  | GetUpdateHistoryAction
  | GetUpdateHistorySuccessAction
  | GetUpdateHistoryFailAction
  | DeleteFloorAction
  | FloorDeleteApiFailAction
  | FloorDeleteSuccessAction
  | UpdatePanelFilterAction
  | CompanyDeleteAction
  | CompanyDeleteSuccessAction
  | CompanyDeleteApiFailAction
  | MoveEntityAction
  | MoveEntityFailAction
  | MoveEntitySuccessAction
  | RecordsToMergeAction
  | MergeRecordsAction
  | MergeRecordsSuccessAction
  | MergeRecordsFailAction
  | CompareRecordsAction
  | CompareRecordsSuccessAction
  | CompareRecordsFailAction
  | CompareRecordsResetAction;

export type DetailEpicActions =
  | DocumentUploaderActionTypes
  | DocumentPanelActionTypes
  | DetailsActionTypes
  | NewSaleActionTypes
  | NewLeaseActionTypes
  | EditRecordActionTypes
  | NotesActionTypes
  | PollingUpdateActionTypes
  | ValuationsActionTypes
  | DebtsActionTypes;
